import { useState, useEffect } from "react"
import { Breadcrumb, Col, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { getCropDiseaseInfo } from "../api/diseases"
import { faInfoCircle, faHandHoldingMedical, faVirusSlash, faImages } from "@fortawesome/free-solid-svg-icons";
import { MultiLineLabelTextField, SingleLineLabelTextField } from "../util/FieldUtility"
import AccordionItem from "../components/Accordions"
import MainLayout from "../layouts/MainLayout"
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";
import Gallery from "../components/Gallery";

const DiseaseGallery = ({images}) => {
    const { t } = useTranslation()
    return (
        <Gallery files={images} title={t("disease.damagePg")} icon={faImages}/>
    )
}

const PestGallery = ({egg_images, larva_images, adult_images, damage_images}) => {
    const { t } = useTranslation()
    return (
        <>
            <Gallery files={egg_images} title={t("disease.eggsPg")} icon={faImages} bg="filled"/>
            <Gallery files={larva_images} title={t("disease.larvaPg")} icon={faImages} bg="filled"/>
            <Gallery files={adult_images} title={t("disease.adultPg")} icon={faImages} bg="filled"/>
            <Gallery files={damage_images} title={t("disease.damagePg")} icon={faImages} bg="filled"/>
        </>
    )
}

const Disease = () => {
    var { groupId, fsId } = useParams()

    const [info, setInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {        
        let isMounted = true; 
        getCropDiseaseInfo(fsId)
        .then(res => {
            if(isMounted) {                
                setInfo(res)
                setIsLoading(false)     
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }, [])

    if(isLoading || !info) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <MainLayout>
            <Row>
                <Col sm="12">
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/group/${groupId}` }}>{info.illness.group.group_identifier}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{info.illness.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <AccordionItem title={t("general.about")} icon={faInfoCircle} bg="filled" defaultOpen={true} >
                        <SingleLineLabelTextField label={t("general.scientificName")} field={info.illness.scientific_name}/>
                        <SingleLineLabelTextField label={t("general.commonName")} field={info.illness.common_name}/>
                        <SingleLineLabelTextField label={t("general.otherName")} field={info.illness.other_names}/>
                        <MultiLineLabelTextField label={t("general.description")} field={info.illness.general_description}/>
                    </AccordionItem>
                    <AccordionItem title={t("disease.damage")} icon={faHandHoldingMedical} bg="filled">
                        <MultiLineLabelTextField label={t("disease.damage_description")} field={info.illness.damage_description}/>
                    </AccordionItem>
                    <AccordionItem title={t("disease.control")} icon={faVirusSlash} bg="filled">
                        <MultiLineLabelTextField label={t("disease.control_information")} field={info.illness.control_information}/>
                        <MultiLineLabelTextField label={t("disease.biological_control")} field={info.illness.biological_control}/>
                        <MultiLineLabelTextField label={t("disease.pesticide_control")} field={info.illness.pesticide_control}/>
                    </AccordionItem>
                    { (info.illness.type === "Disease") ? <DiseaseGallery images={info.damage_files} /> : <PestGallery egg_images={info.egg_files} larva_images={info.larva_files} adult_images={info.adult_files} damage_images={info.damage_files} />}
                </Col>
            </Row>
        </MainLayout>
    )
}

export default Disease
import appData from '../app.json';
import { getToken } from '../storage/local_storage';

export const getHeaders = async () => {

    const token = await getToken();   

    const headers = {
        'Content-Type':'application/json'
    }

    if(token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return headers;
}

export const post = async (destination, body) => {
    const headers = await getHeaders();
    
    //console.log(`${appData.apiUrl}${destination}`)

    const result = await fetch(`${appData.apiUrl}${destination}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    });
    
    if(result.ok) {
        return await result.json();
    }
    throw { error: result.status };
}

export const get = async (destination) => {
    const headers = await getHeaders();

    const result = await fetch(`${appData.apiUrl}${destination}`, {
        method: 'GET',
        headers: headers
    });

    if(result.ok) {
        return await result.json();
    }
    throw { error: result.status };
}
import { get, post } from "./fetch";

export const login = (email, password) => {
    var userInfo = {
        email: email,
        password: password
    }
    return post('/api/login', userInfo);    
}

export const verifyToken = (email, token) => {
    var userInfo = {
        email: email,
        token: token
    }
    return post('/api/verify', userInfo);
}

export const register = (userInfo) => {   
    return post('/api/register', userInfo);
}

export const getRegistrationCountries = () => {
    var countries = get(`/api/get-registration-countries`);
    return countries;
}

export const getGroupsByCountry = (id) => {
    var groups = get(`/api/get-registration-groups/${id}`);
    return groups;
}

export const hasAdminRoles = () => {
    var response = get('/api/user/can-post');
    return response;
}
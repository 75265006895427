import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "../pages/Main";
import ScrollToTop from "../components/ScrollToTop";
import Group from "../pages/Group";
import Factsheet from "../pages/Factsheet";
import Disease from "../pages/Disease";
import AnimalFactsheet from "../pages/AnimalFactsheet";
import AnimalDisease from "../pages/AnimalDisease";

const AppRouter = ({ groups, children }) => {
    return (
        <Router>
            { children }
            <Routes>
                <Route exact path="/" element={ <Main groups={groups}/> } />
                <Route exact path="/group/:groupId" element={ <Group /> } />
                <Route exact path="/group/:groupId/product/factsheet/:fsId" element={ <Factsheet /> } />
                <Route exact path="/group/:groupId/product/disease/:fsId" element={ <Disease /> } />
                <Route exact path="/group/:groupId/animal/factsheet/:fsId" element={ <AnimalFactsheet /> } />
                <Route exact path="/group/:groupId/animal/disease/:fsId" element={ <AnimalDisease /> } />
            </Routes>
            <ScrollToTop />
        </Router>
    )
}

export default AppRouter
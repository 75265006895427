import appData from '../app.json';
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Col, ListGroup, Collapse, ListGroupItem }from "react-bootstrap"
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./MainMenu.scss"
import InstallPWAButton from "./InstalPWA";
import lngs from "../util/Langs";
import { hasAdminRoles } from "../api/authentication";

export const AdministratorLink = () => {
    const { t, i18n } = useTranslation()
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        let isMounted = true; 
        hasAdminRoles()
        .then((res) => {
            if(isMounted) {
                if(res === 1) {
                    setIsAdmin(true)
                }
            }
        })
        return () => { isMounted = false };
    }, [])

    if(isAdmin) {
        var lang = i18n.language
        var adminURL = `${appData.apiUrl}/admin`

        if(lang !== 'en') {
            adminURL = `${appData.apiUrl}/${lang}/admin`
        }        

        return (
            <a href={adminURL} target="_blank" className="btn btn-success">
                {t('general.administration')}
            </a>
        )
    }
    return null
}

export const MainMenuContainer = ({children}) => {
    return (
        <Col xs='11' sm='8' md='6' style={{ backgroundColor: "white", padding:"20px", borderRadius:"10px" }}>
            {children}
        </Col>
    )
}

const MainMenu = ({currentPath="/", handleCloseOffcanvas=null, groups=null, logoutCallback}) => {    

    const MenuListGroupItem = ({path, children}) => {

        return (
            <ListGroupItem className="d-flex justify-content-between align-items-center" style={{backgroundColor: (path === currentPath ? "#f8f9fa" : null)}}> 
                {(path !== currentPath) ? (
                    <Link to={path} onClick={handleCloseOffcanvas}>
                        {children}
                    </Link>
                ) : <span>{children}</span>}
                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </ListGroupItem>
        )
    }

    const getOpenItemByPath = (path) => {
        return 0
    }

    const [openCollapse, setOpenCollapse] = useState(getOpenItemByPath(currentPath))
    const { t, i18n } = useTranslation()

    return (
        <Col xs={12} className="d-grid gap-2">
            <Button onClick={() => setOpenCollapse(1)}>
                {t('general.groups')}                
            </Button>
            <Collapse in={(openCollapse===1)}>                
                <ListGroup variant="flush" className="main-menu-items">
                    {groups.map((group, i) => {
                        return(
                            <MenuListGroupItem path={ `/group/${group.id}` } key={i}>
                                { group.group_identifier }
                            </MenuListGroupItem>
                        )
                    })}
                </ListGroup>
            </Collapse>    
            <AdministratorLink />  
            <Button variant="secondary" onClick={() => {logoutCallback()}} >Logout</Button>            
            <hr />
            <ButtonGroup>
                {Object.keys(lngs).map((lng) => (
                    <Button variant="info" key={lng} style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }} onClick={() => i18n.changeLanguage(lng)}>
                        {lngs[lng].nativeName}
                    </Button>
                ))}
            </ButtonGroup>
            <InstallPWAButton />
        </Col>
    )
}

export default MainMenu
import { Col, Row } from "react-bootstrap";
import GroupList from "../components/GroupList";
import {GenearalNoticesList} from "../components/NoticesList";
import MainLayout from "../layouts/MainLayout";
import "./Main.scss";

const Main = ({groups}) => {
    return (
        <MainLayout>
            <Row >
                <Col lg={7} sm={12} className="mb-4">
                    <GroupList groups={groups} />                    
                </Col>
                <Col lg={5} sm={12}>
                    <GenearalNoticesList />
                </Col>
            </Row>
        </MainLayout>
    )
}

export default Main
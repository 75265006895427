import { useState, useEffect } from "react"
import { Breadcrumb, Col, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { faInfoCircle, faHandHoldingMedical, faVirusSlash, faImages } from "@fortawesome/free-solid-svg-icons";
import { MultiLineLabelTextField, SingleLineLabelTextField } from "../util/FieldUtility"
import AccordionItem from "../components/Accordions"
import MainLayout from "../layouts/MainLayout"
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";
import Gallery from "../components/Gallery";
import { getAnimalDiseaseInfo } from "../api/diseases";

const DiseaseGallery = ({symptom_files}) => {

    const { t } = useTranslation()

    return (
        <Gallery files={symptom_files} title={t("animalDisease.symptoms_gallery")} icon={faImages}/>
    )
}

const ParasiteGallery = ({symptom_files, parasite_files}) => {

    const { t } = useTranslation()

    return (
        <>
            <Gallery files={symptom_files} title={t("animalDisease.symptoms_gallery")} icon={faImages} bg="filled"/>
            <Gallery files={parasite_files} title={t("animalDisease.parasite_gallery")} icon={faImages} bg="filled"/>
        </>
    )
}

const AnimalDisease = () => {

    var { groupId, fsId } = useParams()

    const [info, setInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {        
        let isMounted = true; 
        getAnimalDiseaseInfo(fsId)
        .then(res => {
            if(isMounted) {                
                setInfo(res)
                setIsLoading(false)     
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }, [])

    if(isLoading || !info) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <MainLayout>
            <Row>
                <Col sm="12">
                <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/group/${groupId}` }}>{info.illness.group.group_identifier}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{info.illness.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <AccordionItem title={t("general.scientificName")} icon={faInfoCircle} bg="filled" defaultOpen={true} >
                        <SingleLineLabelTextField label={t("general.scientificName")} field={info.illness.scientific_name}/>
                        <SingleLineLabelTextField label={t("general.commonName")} field={info.illness.common_name}/>
                        <SingleLineLabelTextField label={t("general.otherName")} field={info.illness.other_names}/>
                        <MultiLineLabelTextField label={t("general.description")} field={info.illness.general_description}/>
                    </AccordionItem>
                    <AccordionItem title={t("animalDisease.symptoms")} icon={faHandHoldingMedical} bg="filled">
                        <MultiLineLabelTextField label={t("animalDisease.symptomatology")} field={info.illness.symptomatology}/>
                    </AccordionItem>
                    <AccordionItem title={t("animalDisease.prevention")} icon={faVirusSlash} bg="filled">
                        <MultiLineLabelTextField label={t("animalDisease.prevention")} field={info.illness.prevention}/>
                        <MultiLineLabelTextField label={t("animalDisease.control_treatment")} field={info.illness.control_treatment}/>
                    </AccordionItem>
                    { (info.illness.type === "Disease") ? <DiseaseGallery images={info.damage_files} /> : <ParasiteGallery symptom_files={info.symptom_files} parasite_files={info.parasite_files} />}
                </Col>
            </Row>
        </MainLayout>
    )
}

export default AnimalDisease
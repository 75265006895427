const getItemFromStorage = async(itemName) => {
    try {
        const value = await localStorage.getItem(itemName)
        if(value !== null) {
            return value;
        } 
        return null;
    } catch(e) {
        return null;
    }
}

export const getToken = async () => {
    const token = await getItemFromStorage('@auth_token');
    if(token) {
        return token  
    }
    return null
}

export const getLang = async () => {
    const lang = await getItemFromStorage('@lang')
    if(lang) {
        return lang
    }
    return 'en'
}

export const setToken = async (token, expirationDate=null) => {
    try {
      await localStorage.setItem('@auth_token', token);
    } catch (e) {
        console.log(e)
        return null;
    }
};

export const setLang = async (lang) => {
    try {
      await localStorage.setItem('@lang', lang);
    } catch (e) {
        console.log(e)
        return null;
    }
};

export const removeToken = async () => {
    try {
        await localStorage.removeItem('@auth_token')
    } catch (e) {
        return null;
    }
}

export const removeLang = async () => {
    try {
        await localStorage.removeItem('@lang')
    } catch (e) {
        return null;
    }
}
import { useState, useEffect } from "react"
import { Breadcrumb, Col, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import LoadingSpinner from "../components/LoadingSpinner"
import { MultiLineLabelTextField, SingleLineLabelTextField } from "../util/FieldUtility"
import { faInfoCircle, faCloud, faImages } from "@fortawesome/free-solid-svg-icons";
import AccordionItem from "../components/Accordions"
import MainLayout from "../layouts/MainLayout"
import { useTranslation } from 'react-i18next';
import Gallery from "../components/Gallery"
import { getAnimalFactsheetInfo } from "../api/factsheets"

const AnimalFactsheet = () => {

    var { groupId, fsId } = useParams()

    const [info, setInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {        
        let isMounted = true; 
        getAnimalFactsheetInfo(fsId)
        .then(res => {
            if(isMounted) {                
                setInfo(res)
                setIsLoading(false)     
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }, [])

    if(isLoading || !info) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <MainLayout>
            <Row>
                <Col sm={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/group/${groupId}` }}>{info.group.group_identifier}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{info.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <AccordionItem title={t("general.about")} icon={faInfoCircle} bg="filled" defaultOpen={true} >
                        <SingleLineLabelTextField label={t("general.scientificName")} field={info.scientific_name}/>
                        <SingleLineLabelTextField label={t("general.commonName")} field={info.common_name}/>
                        <SingleLineLabelTextField label={t("general.otherName")} field={info.other_names}/>
                        <MultiLineLabelTextField label={t("general.description")} field={info.description}/>
                    </AccordionItem>
                    <AccordionItem title={t("general.productionBestPractices")} icon={faCloud} bg="filled">
                        <MultiLineLabelTextField label={t("animalFs.breeds")} field={info.breeds}/>
                        <MultiLineLabelTextField label={t("animalFs.general")} field={info.general}/>
                        <MultiLineLabelTextField label={t("animalFs.nutrition")} field={info.nutrition}/>
                        <MultiLineLabelTextField label={t("animalFs.housing")} field={info.housing}/>
                        <MultiLineLabelTextField label={t("animalFs.breeding_systems")} field={info.breeding_systems}/>
                        <MultiLineLabelTextField label={t("animalFs.reproduction")} field={info.reproduction}/>
                        <MultiLineLabelTextField label={t("animalFs.health_diseases")} field={info.health_diseases}/>
                        <MultiLineLabelTextField label={t("animalFs.storage_processing")} field={info.storage_processing}/>
                    </AccordionItem>
                    <Gallery files={info.files} title={t("animalFs.photoGallery")} icon={faImages}/>
                </Col>
            </Row>
        </MainLayout>
    )

}

export default AnimalFactsheet
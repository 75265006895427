import { useState, useEffect } from "react"
import { Col, Form, Row, Button, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserPlus } from "@fortawesome/free-solid-svg-icons"
import LoginLayout from "../layouts/LoginLayout"
import { useTranslation } from "react-i18next"
import { getGroupsByCountry, getRegistrationCountries, register } from "../api/authentication"
import LoadingSpinner from "./LoadingSpinner";
import lngs from "../util/Langs"
const RegistrationCountryList = ({ country, setSelectedGroups}) => {

    const [isLoading, setIsLoading] = useState(true)
    const [groups, setGroups] = useState([])
    const [groupsChecks, setGroupsChecks] = useState(
        new Array(groups.length).fill(false)
    );

    useEffect(() => {
        let isMounted = true; 
        getGroupsByCountry(country)
        .then(res => {
            if(isMounted) {                
                setGroups(res)
                setIsLoading(false)            
            }
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            setGroupsChecks(
                new Array(groups.length).fill(false)
            )
        })
        return () => { isMounted=false }
    }, [country, groups.length])

    const handleCheckItem = (position) => {
        const updatedCheckedState = groupsChecks.map((item, index) =>
            index === position ? !item : item
        )
        setGroupsChecks(updatedCheckedState)   

        var newSelectedItems = []

        groups.forEach((element, key) => {
            if(updatedCheckedState[key]) {
                newSelectedItems.push(element.id)
            }            
        });

        setSelectedGroups(newSelectedItems)
    }

    if(!country || isLoading) {
        return null
    }
    
    if(!groups) {
        return (
            <h4>No groups available for this country</h4>
        )
    }

    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <Form.Group style={{ marginBottom:"10px" }}>
            {groups.map((group, key) => {
                return (
                    <Form.Check
                        key={key} 
                        type="switch"
                        id={`groupchk-${group.id}`}
                        label={group.group_identifier}
                        value={group.id}
                        checked={groupsChecks[key]}
                        onChange={(event) => handleCheckItem(key)}
                    />
                )
            })}
        </Form.Group>
    )
}



const RegistrationForm = ({ handleReturnToLogin, loginCallback }) => {

    const { t } = useTranslation()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [country, setCountry] = useState(-1)
    const [groups, setGroups] = useState([])
    const [lang, setLang] = useState('en')

    const [countries, setCountries] = useState('')    
    
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)      
    const [modalMessage, setModalMessage] = useState(t('register.errorRegistration'))

    const handleClose = () => setShowModal(false)

    const checkErrors = () => {
        var errorMessage = ''
        var hasErrors = false;
        if(!name) {
            errorMessage += '- Name is required \n'
            hasErrors = true;
        }
        if(!email) {
            errorMessage += '- Email is required \n'
            hasErrors = true;
        }
        if(!password) {
            errorMessage += '- Password is required \n'
            hasErrors = true;
        }
        if(!passwordConfirm) {
            errorMessage += '- Password confirmation is required \n'     
            hasErrors = true;       
        }
        if(password && passwordConfirm) {
            if(password !== passwordConfirm) {
                errorMessage += '- Password and password confirmation do not match \n'
                hasErrors = true;
            }
        }

        if(hasErrors) {
            setModalMessage(errorMessage)
        }        

        return hasErrors
    }

    const submit = () => {
        if(checkErrors()) {
            setShowModal(true)
        } else {
            var userInfo = {
                name: name,
                email: email,
                password: password,
                password_confirmation: passwordConfirm,
                groups: groups,
                country: country,
                lang: lang
            }
    
            register(userInfo)
            .then((res) => {
                if(res.status === 'OK') {
                    loginCallback(email, password)
                } else {
                    setModalMessage(t(`register.${res.message}`))
                    setShowModal(true)
                }
            })
        }
        
    }

    useEffect(() => {
        let isMounted = true; 
        getRegistrationCountries()
        .then(res => {
            if(isMounted) {                
                setCountries(res)
                setIsLoading(false)            
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }, [])

    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <LoginLayout>
            <Row className="justify-content-center align-items-center">
                <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('auth.errorTitle')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalMessage}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        {t('auth.close')}
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Col xs={10} md={6} style={{ backgroundColor: "white", padding:"20px", borderRadius:"10px" }}>
                    <Col xs={12}>                        
                        <Form className="form">
                            <h3 className="text-center" style={{ color:"#1C3664", fontWeight:"bold", marginBottom:"40px" }}>{t('auth.welcome')}</h3>
                            <Form.Group controlId="name">
                                <Form.Control placeholder={t('register.fullName')} value={ name } onChange={ (event) => { setName(event.target.value) } }/>
                                <Form.Text className="text-muted">
                                    {t('register.fullNameText')}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Control type="email" placeholder={t('register.email')} value={ email } onChange={ (event) => { setEmail(event.target.value) } }/>
                                <Form.Text className="text-muted">
                                    {t('register.emailText')}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="password" style={{ marginBottom:'0' }}>
                                <Form.Control type="password" placeholder={t('register.password')} value={ password } onChange={ (event) => { setPassword(event.target.value) } }/>
                            </Form.Group>
                            <Form.Group controlId="confirmpassword" style={{ marginBottom:'10px' }}>
                                <Form.Control type="password" placeholder={t('register.confirmPassword')} value={ passwordConfirm } onChange={ (event) => { setPasswordConfirm(event.target.value) } }/>
                                <Form.Text className="text-muted">
                                    {t('register.confirmPasswordText')}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="country" style={{ marginBottom:'0' }}>
                                <Form.Select aria-label="Country" value={country} onChange={(event) => {setCountry(event.target.value)}}>
                                    <option value='-1'>{t('register.selectCountry')}</option>
                                    {   (countries) ?
                                        countries.map((country, key) => {
                                            return (
                                                <option key={key}  value={country.id}>{country.name}</option>
                                            )
                                        }) : null
                                    }
                                </Form.Select>
                                <Form.Text className="text-muted">
                                    {t('register.selectCountryText')}
                                </Form.Text>
                            </Form.Group>
                            <RegistrationCountryList country={country} setSelectedGroups={setGroups}/>
                            <Form.Group controlId="lang" style={{ marginBottom:'0' }}>
                                <Form.Select aria-label="Language" value={lang} onChange={(event) => {setLang(event.target.value)}}>
                                    {   
                                        Object.keys(lngs).map((lng) => (
                                            <option value={lng}>{lngs[lng].nativeName}</option>
                                        ))
                                    }
                                </Form.Select>
                                <Form.Text className="text-muted">
                                    {t('register.selectCountryText')}
                                </Form.Text>
                            </Form.Group>
                            <div className="text-right mb-3">
                                <Button variant="link" style={{ color:"#1C3664" }} onClick={handleReturnToLogin}>
                                    {t('register.returnToLogin')}
                                </Button>                             
                            </div>
                            <div style={{ textAlign:"center" }}>
                                <div style={{ marginBottom:"20px" }}>
                                    <Button variant="light" onClick={() => { submit() }} style={{ minWidth:'150px', color: "#1C3664", fontWeight: "bold", backgroundColor: "#E8E8E8" }}>
                                        <FontAwesomeIcon icon={faUserPlus} style={{ marginRight:"10px" }}/>
                                        {t('register.registerBtn')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </LoginLayout>
    )
}

export default RegistrationForm
import { useState } from "react"
import { Link, useLocation } from 'react-router-dom'
import { Container, Nav, Navbar, Offcanvas, Button } from "react-bootstrap"
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainMenu from "./MainMenu";
import logo from "../static/images/logo_s.png"
import iicaAzul from "../static/images/iica-azul.png"
import "./OffcanvasNav.scss"


const OffcanvasNav = ({ groups, logoutCallback }) => {

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const location = useLocation();

    var navbarClass = "color"

    return (
        <>
            <Navbar className={`${navbarClass}`}>
                <Container fluid>
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={logo} alt="Agriextapp" className="rounded"/>
                        </Link>
                    </Navbar.Brand>   
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>                            
                            <Button variant="link" className="menu-collapse-button" onClick={handleShow}><FontAwesomeIcon icon={faBars}/></Button>
                        </Nav>
                    </Navbar.Collapse>
                                     
                </Container>
            </Navbar>
            <Offcanvas show={show} onHide={handleClose} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <Link to="/">
                            <img src={iicaAzul} alt="IICA" />
                        </Link>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <MainMenu currentPath={location.pathname} handleCloseOffcanvas={handleClose} groups={groups} logoutCallback={ logoutCallback }/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OffcanvasNav
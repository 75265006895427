import { useState, useEffect } from "react"
import { Breadcrumb, Col, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { getCropFactsheetInfo } from "../api/factsheets"
import LoadingSpinner from "../components/LoadingSpinner"
import { MultiLineLabelTextField, SingleLineLabelTextField, TemperatureTable } from "../util/FieldUtility"
import { faInfoCircle, faCloud, faTractor, faImages } from "@fortawesome/free-solid-svg-icons";
import AccordionItem from "../components/Accordions"
import MainLayout from "../layouts/MainLayout"
import { useTranslation } from 'react-i18next';
import Gallery from "../components/Gallery"

const Factsheet = () => {

    var { groupId, fsId } = useParams()

    const [info, setInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {        
        let isMounted = true; 
        getCropFactsheetInfo(fsId)
        .then(res => {
            if(isMounted) {                
                setInfo(res)
                setIsLoading(false)     
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }, [])

    if(isLoading || !info) {
        return (
            <LoadingSpinner />
        )
    }
    return (
        <MainLayout>
            <Row>
                <Col sm="12">
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/group/${groupId}` }}>{info.group.group_identifier}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{info.title}</Breadcrumb.Item>
                    </Breadcrumb>
                    <AccordionItem title={t("general.about")} icon={faInfoCircle} bg="filled" defaultOpen={true} >
                        <SingleLineLabelTextField label={t("general.scientificName")} field={info.scientific_name}/>
                        <SingleLineLabelTextField label={t("general.commonName")} field={info.common_name}/>
                        <SingleLineLabelTextField label={t("general.otherName")} field={info.other_names}/>
                        <MultiLineLabelTextField label={t("general.description")} field={info.description}/>
                    </AccordionItem>
                    <AccordionItem title={t("general.climateSoil")} icon={faCloud} bg="filled" >
                        <TemperatureTable min_temp={info.min_temperature} max_temp={info.max_temperature} observations={info.temperature_desc} />
                        <MultiLineLabelTextField label={t("baseFs.water")} field={info.water_needs}/>
                        <MultiLineLabelTextField label={t("baseFs.tolerance_waterlogging")} field={info.tolerance_waterlogging}/>
                        <MultiLineLabelTextField label={t("baseFs.drought_tolerance")} field={info.drought_tolerance}/>
                        <MultiLineLabelTextField label={t("baseFs.humidity_tolerance")} field={info.humidity_tolerance}/>
                        <MultiLineLabelTextField label={t("baseFs.wind_tolerance")} field={info.wind_tolerance}/>
                        <MultiLineLabelTextField label={t("baseFs.soil_needs")} field={info.soil_needs}/>
                        <MultiLineLabelTextField label={t("baseFs.nutrient_requirements")} field={info.nutrient_requirements}/>
                    </AccordionItem>
                    <AccordionItem title={t("general.prodHarvestBP")} icon={faTractor} bg="filled" >
                        <MultiLineLabelTextField label={t("baseFs.planting_materials")} field={info.planting_materials}/>
                        <MultiLineLabelTextField label={t("baseFs.varieties")} field={info.varieties}/>
                        <MultiLineLabelTextField label={t("baseFs.spacing")} field={info.spacing}/>
                        <MultiLineLabelTextField label={t("baseFs.field_preparation")} field={info.field_preparation}/>
                        <MultiLineLabelTextField label={t("baseFs.time_harvest")} field={info.time_harvest}/>
                        <MultiLineLabelTextField label={t("baseFs.expected_yields")} field={info.expected_yields}/>
                        <MultiLineLabelTextField label={t("baseFs.harvest")} field={info.harvest}/>
                        <MultiLineLabelTextField label={t("baseFs.storage")} field={info.storage}/>
                        <MultiLineLabelTextField label={t("baseFs.packaging_formats")} field={info.packaging_formats}/>
                    </AccordionItem>
                    <Gallery files={info.files} title={t("general.productPG")} icon={faImages}/>
                </Col>
            </Row>
        </MainLayout>
    )
}

export default Factsheet
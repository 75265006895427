import { get, post } from "./fetch";

export const getGroupNotifications = (id, currentPage) => {
    return get(`/api/home/notices/${id}?page=${currentPage}`);
}

export const getAllNotifications = (currentPage) => {
    return get(`/api/home/notices?page=${currentPage}`);
}

export const canPostNotifications = () => {
    return get('/api/user/can-post');
}

export const postNotification = (text, group) => {
    var notification = {
        text: text,
        group: group
    }
    return post('/api/admin/notice', notification);    
}
import React from 'react';
import './App.scss';
import { useEffect, useState } from 'react';
import { getLang, getToken, removeLang, removeToken, setLang, setToken } from './storage/local_storage';
import { login } from './api/authentication';
import LoginForm from './components/Login';
import LoadingSpinner from './components/LoadingSpinner';
import AuthenticatedApp from './pages/AuthenticatedApp';
import RegistrationForm from './components/Registration';
import { useTranslation } from 'react-i18next';

function App() {
  /**
   * Possible loginStatus:
   * LOGIN_FULFILLED
   * LOGIN_PENDING
   * LOGIN_FORGOT_PW
   * REGISTERING_USER
   * 
   */
  const [loginStatus, setLoginStatus] = useState('') 
  const [isLoading, setIsLoading] = useState(true)
  const { i18n } = useTranslation()
  useEffect(() => {
    handleLoggedIn()
  }, [])

  const handleGoToRegister = () => {
    setLoginStatus('REGISTERING_USER')
  }

  const handleReturnToLogin = () => {
    setLoginStatus('LOGIN_PENDING')
  }

  const handleLoggedIn = async () => {
    const token = await getToken()    
    if(token) {
      i18n.changeLanguage(await getLang())
      setLoginStatus('LOGIN_FULFILLED')
    } else {
      setLoginStatus('LOGIN_PENDING')
    }
    setIsLoading(false)
  }

  const handleLogin = async (userName, password) => {
    const loginResult = await login(userName, password)
    if(loginResult.status === 'OK') {
      await setToken(loginResult.user_info)
      i18n.changeLanguage(loginResult.lang)
      await setLang(loginResult.lang)
      setLoginStatus('LOGIN_FULFILLED')
      return true
    }
    setLoginStatus('LOGIN_PENDING')
    return false
  }

  const handleLogout = async () => {
    setLoginStatus('LOGIN_PENDING')
    await removeToken(null)
    await removeLang()
  }

  const renderSplashScreen = () => {
    return (
      <LoadingSpinner />
    )
  }

  const renderLoginScreen = () => {
    return (
      <div className="app-container">
        <LoginForm loginCallback={ handleLogin } registerCallback={ handleGoToRegister }/>
      </div>
    )
  }

  const renderRegisterScreen = () => {
    return (
      <div className="app-container">
        <RegistrationForm handleReturnToLogin={ handleReturnToLogin } loginCallback={ handleLogin }/>
      </div>
    )
  }

  const renderApplication = () => {
    return (
      <AuthenticatedApp logoutCallback={ handleLogout }/>
    )
  }

  const render = () => {
    if(!isLoading) {
      switch (loginStatus) {
        case 'LOGIN_PENDING':
          return renderLoginScreen()
        case 'LOGIN_FORGOT_PW':
          break;
          //return renderForgotPwScreen()
        case 'REGISTERING_USER':
          return renderRegisterScreen()
        default:
          return renderApplication()
      }
    }
    return renderSplashScreen()
  }

  return (
    render()
  );
}

export default App;

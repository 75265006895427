import { useState, useEffect } from "react";
import { getGroups } from "../api/groups";
import LoadingSpinner from "../components/LoadingSpinner";
import OffcanvasNav from "../components/OffcanvasNav";
import AppRouter from "../routing/AppRouter";

const loadGroups = (groups, setGroups, setLoading, isMounted) => {
    getGroups()
    .then(res => {
        if(isMounted) {
            setGroups(res)
            setLoading(false)
        }
    })
    .catch(error => {
        console.log(error)
    })
}

const AuthenticatedApp = ({ logoutCallback }) => {
    const [groups, setGroups] = useState(null) 
    const [ isLoading, setIsLoading ] = useState(true)

    useEffect(() => {
        let isMounted = true; 
        loadGroups(groups, setGroups, setIsLoading, isMounted)
        return () => { isMounted = false };
    }, [])

    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <AppRouter groups={groups}>
          <header>
            <OffcanvasNav groups={groups} logoutCallback={ logoutCallback }></OffcanvasNav>
          </header>
        </AppRouter>
    )
}

export default AuthenticatedApp
import { get } from "./fetch";

export const getFactsheets = (groupId, type) => {
    return get(`/api/group/factsheets/${groupId}/${type}`);    
}

export const getCropFactsheetInfo = (factsheetId) => {
    return get(`/api/base_record/get/${factsheetId}`);
}

export const getAnimalFactsheetInfo = (factsheetId) => {
    return get(`/api/animal_factsheet/get/${factsheetId}`);
}
import { Image, Row, Col } from "react-bootstrap"
import { format } from "date-fns";
import "./FieldUtility.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faFileDownload } from "@fortawesome/free-solid-svg-icons"
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import { useTranslation } from "react-i18next";

export const SingleLineLabelTextField = ({ label, field }) => {
    if(field) {
        return (
            <Row style={{ marginTop: "20px" }}>
                <Col xs={4}>
                    <span className="field--label">{ label }</span>
                </Col>
                <Col xs={8}>
                    <span className="field--text">{field}</span>
                </Col>
            </Row>
        )
    }

    return null
}

export const SingleLineLabelDateField = ({ label, field }) => {
    if(field) {
        if(field.length > 0) {
            const fieldDate = field
            return (
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={4}>
                        <span className="field--label">{ label }</span>
                    </Col>
                    <Col xs={8}>
                        <span className="field--text">
                            { format(new Date(fieldDate),  "dd-MM- yyyy") }
                        </span>
                    </Col>
                </Row>
            )
        }
    }

    return null
}

export const SingleLineLabelLinkField = ({ label, field, linkText }) => {
    if(field) {
        if(field.length > 0) {
            const fieldUrl = field
            return (
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={4}>
                        <span className="field--label">{ label }</span>
                    </Col>
                    <Col xs={8}>
                        <a href={fieldUrl} target="_blank" rel="noopener noreferrer" className="field--link">
                            <FontAwesomeIcon icon={faLink} />
                            { linkText }
                        </a>                        
                    </Col>
                </Row>
            )
        }
    }

    return null
}

export const MultiLineLabelTextField = ({ label, field }) => {
    if(field) {
        if(field.length > 0) {
            const fieldHtml = field
            return (
                <>
                    <Row style={{ marginTop: "20px", marginBottom: "10px" }}>
                        <Col xs={12}>
                            <span className="field--label">{ label }</span>
                        </Col>  
                        <Col xs={12}>
                            <div className="field--html-text" dangerouslySetInnerHTML={{__html: fieldHtml}}></div>
                        </Col>             
                    </Row>
                </>
            )
        }
    }

    return null
}

export const TemperatureTable = ({ min_temp, max_temp, observations }) => {
    const { t } = useTranslation()
    if(min_temp || max_temp || observations) {
        return (
            <Table className="table table-bordered">
                <Thead>
                    <Tr>
                        {min_temp ?  <Th>{t("baseFs.minTemp")}</Th> : null}
                        {max_temp ?  <Th>{t("baseFs.max_temp")}</Th> : null}
                        {observations ?  <Th>{t("baseFs.temp_observations")}</Th> : null}
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        {min_temp ?  <Td>{min_temp}</Td> : null}
                        {max_temp ?  <Td>{max_temp}</Td> : null}
                        {observations ?  <Td>{observations}</Td> : null}
                    </Tr>
                </Tbody>
            </Table>
        )
    }
    return null    
}
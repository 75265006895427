import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

export const Pager = ({pageCount, currentPage, handlePageClick}) => {

    const {t} = useTranslation()

    if(pageCount <= 1) {
        return null
    }   

    return (
        <ReactPaginate
        pageCount={pageCount}
        initialPage={currentPage - 1}
        previousLabel={t("general.previous")}
        nextLabel={t("general.next")}
        breakLabel={'...'}
        containerClassName={'pagination justify-content-center'}
        pageClassName={'page-item'}
        activeClassName={'active'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        previousLinkClassName={'page-link'}
        onPageChange={handlePageClick}/>
    )
}
import { useState, useEffect } from "react"
import { faBug, faEye, faFileContract } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Nav } from "react-bootstrap"
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import LoadingSpinner from "./LoadingSpinner"
import { getFactsheets } from "../api/factsheets"
import { getDiseases } from "../api/diseases"
import { format } from "date-fns"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

const RecordTable = ({records, isLoading, groupId, groupType,type}) => {

    const { t } = useTranslation()

    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    const renderTable = () => {
        return (
            records.map((record, i) => {
                return (
                    <Tr key={i}>
                        <Td>{record.title}</Td>
                        <Td>{record.scientific_name}</Td>
                        <Td>{ format(new Date(record.created_at), "MM-dd-yyyy") }</Td>
                        <Td>{ format(new Date(record.updated_at), "MM-dd-yyyy") }</Td>
                        <Td className="d-grid gap-2"><Link className="btn btn-primary" to={`/group/${groupId}/${groupType}/${type}/${record.id}`}><FontAwesomeIcon icon={faEye}/> {t('general.view')}</Link></Td>
                    </Tr>
                )
            })
        )
    }

    return (
        <Table className="table table-striped table-bordered mt-4">
            <Thead>
                <Tr>
                    <Th>{t('general.title')}</Th>
                    <Th>{t('general.scientificName')}</Th>
                    <Th>{t("general.created")}</Th>
                    <Th>{t("general.updated")}</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderTable()}
            </Tbody>
        </Table>
    )
}

const getFactsheetsList = (groupId, group_type, setIsLoading, setRecords) => {
    getFactsheets(groupId, group_type)
    .then(res => {
        setRecords(res)
        setIsLoading(false)
    })
    .catch(error => {
        console.log(error)
    })
} 

const getDiseasesList = (groupId, group_type, setIsLoading, setRecords) => {
    getDiseases(groupId, group_type)
    .then(res => {
        setRecords(res)
        setIsLoading(false)
    })
    .catch(error => {
        console.log(error)
    })
} 

export const FactsheetNavContainer = ({groupId, group_type}) => {

    const { t } = useTranslation()

    const [currentFsType, setCurrentFsType] = useState("factsheet")

    const [records, setRecords] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=> {
        let isMounted = true; 
        getFactsheets(groupId, group_type)
        .then(res => {
            if(isMounted) {                
                setRecords(res)
                setIsLoading(false)            
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }, [groupId])

    const changeTab = (selectedKey) => {
        setCurrentFsType(selectedKey)
        switch (selectedKey) {
            case "disease":
                getDiseasesList(groupId, group_type, setIsLoading, setRecords)
                break;        
            default:
                getFactsheetsList(groupId, group_type, setIsLoading, setRecords)
                break;
        }        
    } 

    const GroupName = () => {
        switch (group_type) {
            case "animal":
                return (
                    <Nav.Link eventKey="disease"><FontAwesomeIcon icon={faBug} /> {t('general.diseasesParasites')}</Nav.Link>                    
                )        
            default:
                return (
                    <Nav.Link eventKey="disease"><FontAwesomeIcon icon={faBug} /> {t('general.diseasesParasites')}</Nav.Link>   
                )
        }
    }

    return (
        <>
            <Nav 
                justify 
                variant="tabs" 
                activeKey={currentFsType}
                onSelect={(selectedKey) => changeTab(selectedKey)}
                >
                <Nav.Item>
                    <Nav.Link eventKey="factsheet"><FontAwesomeIcon icon={faFileContract} /> {t('general.factsheets')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <GroupName />
                </Nav.Item>
            </Nav>
            <RecordTable records={records} isLoading={isLoading} groupId={groupId} groupType={group_type} type={currentFsType}/>
        </>
    )
}
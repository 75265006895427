import { Container } from "react-bootstrap"

const MainLayout = ({children}) => {
    return(
        <main>
            <div style={{ height:"100vh", backgroundSize:"cover", backgroundColor:"#C5DAE3"}}>            
                <Container fluid style={{ height:"100vh",backgroundColor:"white", paddingTop:"20px" }}>
                    {children}
                </Container>
            </div>
        </main>
    )
}

export default MainLayout
import { Button, ButtonGroup, Col, Container, Image, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import logo from "../static/images/logo_s.png";
import lngs from "../util/Langs";

const LoginLayout = ({children}) => {
    const { t, i18n } = useTranslation()
    return(
        <div style={{ backgroundColor:"#C5DAE3", height:"100vh", backgroundSize:"cover", backgroundRepeat:"no-repeat" }}>            
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col xs={10} md={6} style={{textAlign:"right"}}>
                        <ButtonGroup>
                            {Object.keys(lngs).map((lng) => (
                                <Button variant="link" key={lng} style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal', color: "black" }} onClick={() => i18n.changeLanguage(lng)}>
                                    {lngs[lng].nativeName}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                    <Col xs={10} md={6} style={{ textAlign:"center", padding:"10px", borderRadius:"10px", backgroundColor: "white", marginTop:"80px", marginBottom:"80px" }}>
                        <Image src={logo} style={{ maxWidth:"280px", width:"100%" }}/>
                    </Col>
                </Row>
                {children}
            </Container>
        </div>
    )
}

export default LoginLayout
import { useState, useEffect } from "react"
import { Breadcrumb, Card, Col, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { getGroupInfo } from "../api/groups"
import LoadingSpinner from "../components/LoadingSpinner"
import MainLayout from "../layouts/MainLayout"
import { faIdCard, faEnvelope, faPhone, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FactsheetNavContainer } from "../components/FactsheetNavContainer"
import { useTranslation } from "react-i18next"
import "./Group.scss"
import { GroupNoticesList } from "../components/NoticesList"

export const GroupGeneralInformation = ({group}) => {

    const {t} = useTranslation()

    const EmergencyNumber = ({ phone_number }) => {
        if(!phone_number) {
            return null
        }
        
        switch (phone_number) {
            case "N.A.":
                return null   
            default:
                return (
                    <dl>
                        <dt> <FontAwesomeIcon icon={faPhone} /></dt>
                        <dd><a href="tel:{phone_number}">{phone_number}</a></dd>
                    </dl>
                )
        }
    }

    return(                
        <Card>
            <Card.Header><FontAwesomeIcon icon={faInfo} /> {t('general.groupContact')}</Card.Header>
            <Card.Body>
                <div className="title-container">
                    <h2>{group.group_identifier}</h2>
                    <h3>{group.country.name}</h3>
                </div>
                <div className="contact-container">
                    <dl>
                        <dt><FontAwesomeIcon icon={faIdCard} /></dt>
                        <dd>{group.info_contact} ({group.info_organization})</dd>         
                    </dl>
                    <dl>
                        <dt><FontAwesomeIcon icon={faEnvelope} /></dt>
                        <dd><a href="mailto:{group.info_email}">{group.info_email}</a></dd>
                    </dl>
                    <EmergencyNumber phone_number={group.phone_number} />
                </div>
            </Card.Body>
        </Card>
    )
}

const Group = () => {

    var { groupId } = useParams()

    const [group, setGroup] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let isMounted = true; 
        getGroupInfo(groupId)
        .then(res => {
            if(isMounted) {                
                setGroup(res)
                setIsLoading(false)            
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }, [groupId])

    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <MainLayout>
            <Row>
                <Col sm={12} md={8}>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>{group.group_identifier}</Breadcrumb.Item>
                    </Breadcrumb>
                    <FactsheetNavContainer groupId={groupId} group_type={group.type}/>
                </Col>
                <Col sm={12} md={4}>
                    <GroupNoticesList groupId={groupId} />
                    <GroupGeneralInformation group={group} />
                </Col>
            </Row>
            
        </MainLayout>
    )
}

export default Group
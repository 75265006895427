import { useState, useEffect } from "react";
import { Col, ListGroup, ListGroupItem, Row, Image, Card, Form, Button } from "react-bootstrap";
import { getAllNotifications, getGroupNotifications, postNotification } from "../api/notifications";
import LoadingSpinner from "./LoadingSpinner"
import avatar from '../static/images/avatar.png'
import { faArrowRight, faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Pager } from "./Pager";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import "./NoticesList.scss"

const NoticeLink = ({notice, children}) => {
    if(notice.type === 'notice') {
        return (
            children
        )
    }
    var type = "product"
    var subType = "factsheet"
    var id = null
    switch (notice.type) {
        case "illness_record":
            type="product"
            var subType = "disease"
            id=notice.illness_record_id
            break
        case "animal_factsheet":
            type="animal"
            id=notice.animal_factsheet_id
            break
        case "animal_disease":
            type="animal"
            subType="disease"
            id=notice.animal_disease_id
            break
        default:
            id=notice.base_record_id
            break;
    } 

    return (
        <Link to={`/group/${notice.group_id}/${type}/${subType}/${id}`} className="notice-list-item--link">
            {children}
        </Link>
    )
}

const NoticesListItem = ({notice}) => {
    const {t, i18n } = useTranslation()
    if(!notice) {
        return null
    } 

    return (
        <ListGroup.Item className="notice-list-item">
            <NoticeLink notice={notice}>
                <Row>                    
                    <Col sm={2} md={1} xs={3}>
                        <Image src={avatar} alt={notice.post_user} style={{ width:"100%" }} roundedCircle={true}/>
                    </Col>
                    <Col sm={10} md={11} xs={9} className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5>{notice.post_user} <small><i>{t('notices.postedOn')}&nbsp;
                                {
                                new Intl.DateTimeFormat(i18n.language, {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit"
                                }).format(Date.parse(notice.created_at))}
                            </i></small></h5>
                            {((notice.post_group) ? <h6>{notice.post_group}</h6> : null)}
                            <div dangerouslySetInnerHTML={{ __html: notice.text }}></div>
                        </div>
                        { notice.type !== 'notice' ? 
                            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                         : null }                        
                    </Col>                    
                </Row>
            </NoticeLink>
        </ListGroup.Item>
    )
}

const NoticePostForm = ({groupId, handleLoadNotifications}) => {
    const {t} = useTranslation()
    const [canPost, setCanPost] = useState(0)
    const [noticeText, setNoticeText] = useState('')

    const submit = () => {
        postNotification(noticeText, groupId)
        .then(res => {
            setNoticeText('')
            Swal.fire({
                title: t('notices.success'),
                text:  t('notices.successMessage'),
                icon: 'success',
                confirmButtonText: t('notices.close')
            })
            handleLoadNotifications()
        })
        .catch(error => {
            Swal.fire({
                title: t('notices.error'),
                text:  t('notices.errorMessage'),
                icon: 'error',
                confirmButtonText: t('notices.close')
            })
        })
    }

    return (
        <Form>
            <Form.Group className="mb-3" controlId="notice">
                <Form.Label>{t('notices.formText')}</Form.Label>
                <Form.Control as="textarea" rows={3} value={noticeText}  onChange={ (event) => { setNoticeText(event.target.value) } } />
            </Form.Group>
            <Row>
                <Col sm={12} md={6}>
                    <Button onClick={() => { submit() } }>
                        <FontAwesomeIcon icon={faComment} style={{ marginRight:"10px" }}/>
                        {t('notices.formSubmit')}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export const GroupNoticesList = ({ groupId }) => {

    const {t} = useTranslation()

    const [isLoading, setIsLoading] = useState(true)
    const [notices, setNotices] = useState()
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)

    const handlePageClick = (data) => {
        var page = data.selected >= 0 ? data.selected + 1 : 1;
        setCurrentPage(page)
    }

    const loadNotifications = () => {
        let isMounted = true; 
        getGroupNotifications(groupId, currentPage)
        .then(res => {
            if(isMounted) {                
                setNotices(res.data)
                setPageCount(res.last_page)
                setIsLoading(false)            
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    }

    useEffect(() => {
        
        return loadNotifications()
        
    }, [groupId, currentPage])

    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <Card className="mb-4">
            <Card.Header><FontAwesomeIcon icon={faComment} /> {t('general.whatsNew')}</Card.Header>
            <Card.Body>
                <NoticePostForm groupId={groupId} handleLoadNotifications={loadNotifications}/>
                <hr />
                <ListGroup variant="flush">
                    {notices.map((notice, key) => {
                        return (
                            <NoticesListItem key={key} notice={notice} />
                        )
                    })}
                </ListGroup>
                <Pager currentPage={currentPage} pageCount={pageCount} handlePageClick={handlePageClick}/>
            </Card.Body>
        </Card>
        
    )
}

export const GenearalNoticesList = () => {

    const {t} = useTranslation()

    const [isLoading, setIsLoading] = useState(true)
    const [notices, setNotices] = useState()
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)

    const handlePageClick = (data) => {
        var page = data.selected >= 0 ? data.selected + 1 : 1;
        setCurrentPage(page)
    }

    useEffect(() => {
        let isMounted = true; 
        getAllNotifications(currentPage)
        .then(res => {
            if(isMounted) {                
                setNotices(res.data)
                setPageCount(res.last_page)
                setIsLoading(false)            
            }
        })
        .catch(error => {
            console.log(error)
        })
        return () => { isMounted=false }
    },[currentPage])
    
    if(isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <Card>
            <Card.Header><FontAwesomeIcon icon={faComment} /> {t('general.whatsNew')}</Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    {notices.map((notice, key) => {
                        return (
                            <NoticesListItem key={key} notice={notice} />
                        )
                    })}
                </ListGroup>
                <Pager currentPage={currentPage} pageCount={pageCount} handlePageClick={handlePageClick}/>
            </Card.Body>
        </Card>
        
    )
}

import appData from '../app.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import './Gallery.scss'
import AccordionItem from './Accordions';
import { Navigation, Pagination, EffectCards } from 'swiper';

const Gallery = ({title, icon, files, bg="filled"}) => {
    if(!files) {
        return null
    }

    if(files.length === 0) {
        return null
    }

    return (
        <AccordionItem title={title} icon={icon} bg={bg}>
            <div className="p-2">
                <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    pagination={true}
                    modules={[EffectCards, Pagination]}
                    onSwiper={(swiper) => console.log(swiper)}
                    loop={true}
                    >
                        {
                            files.map((file, i) => {
                                return (    
                                    <SwiperSlide key={i} className='gallery-slide'>
                                        <img src={`${appData.apiUrl}/${file.path}`} alt={`Image number ${i}`} />
                                    </SwiperSlide>
                                )
                            })
                        }                
                </Swiper>
            </div>
        </AccordionItem>
    )
}

export default Gallery
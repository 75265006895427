import { get } from "./fetch";

export const getDiseases = (groupId, type) => {
    return get(`/api/group/illness/${groupId}/${type}`);    
}

export const getCropDiseaseInfo = (diseaseId) => {
    return get(`/api/illness_record/get/${diseaseId}`);    
}

export const getAnimalDiseaseInfo = (diseaseId) => {
    return get(`/api/animal_disease/get/${diseaseId}`);    
}
import { get } from "./fetch";


export const getCountries = () => {
    return get('/api/home/countries');
}

export const getGroups = (id = null) => {
    if(!id) {
        return get('/api/home/groups');    
    }
    //Si hay un ID es para el registro
    return get(`/api/home/groups/${id}`);    
}

export const getGroupInfo = (id) => {
    return get(`/api/group/info/${id}`);    
}
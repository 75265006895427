import { Card, Col, ListGroup, Row } from "react-bootstrap"
import "./GroupList.scss";
import { Link } from "react-router-dom";
import { faQuestionCircle, faComment, faCloudDownloadAlt, faUsers, faPaw, faTractor } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const GroupIcon = ({ groupType }) => {
    switch (groupType) {
        case 'animal':
            return (
                <FontAwesomeIcon icon={faPaw} size="lg" className="mt-3"/>
            )
        default:
            return (
                <FontAwesomeIcon icon={faTractor} size="lg" className="mt-3"/>
            )
    }
}

const TelegarmLink = ({ groupId }) => {

    const {t} = useTranslation()

    if(!groupId) {
        return null
    }

    return (
        <Col xs={12} md={4} className="d-grid gap-2 mb-2">
            <a href={groupId} className="btn btn-primary">
                <FontAwesomeIcon icon={faComment} /> {t('general.joinChat')}
            </a>
        </Col>
    )
}

const GroupListItem = ({ group, id }) => {

    const {t} = useTranslation()

    return (
        <ListGroup.Item className="group-list--item">            
                <Row>
                    <Col xs={2} md={1} className="d-flex justify-content-start">
                        <GroupIcon groupType={group.type} />
                    </Col>
                    <Col xs={10} md={11}>
                        <Link to={ `/group/${group.id}` } style={{ textDecoration:"none" }}>
                            <h5>{group.group_identifier}</h5>
                            <h6>{ group.country.name }</h6>
                        </Link>                        
                    </Col>
                    <TelegarmLink groupId={group.telegram_group} />
                    <Col xs={12} md={4} className="d-grid gap-2 mb-2">
                        <a href="https://telegram.org/" className="btn btn-info">
                            <FontAwesomeIcon icon={faCloudDownloadAlt} /> {t('general.downloadTelegram')}
                        </a>
                    </Col>
                    {( group.phone_number && group.phone_number !== 'N.A.') ? (
                        <Col xs={12} md={4} className="d-grid gap-2 mb-2">
                            <a href={"tel:" + group.phone_number} className="btn btn-secondary">
                                <FontAwesomeIcon icon={faQuestionCircle} /> {t('general.emergencyContact')}
                            </a>
                        </Col>
                    ) : (
                        <Col xs={12} md={4} className="d-grid gap-2 mb-2">
                            <a href={"mailto:" + group.info_email} className="btn btn-secondary">
                                <FontAwesomeIcon icon={faQuestionCircle} /> {t('general.emergencyContact')}
                            </a>
                        </Col>
                    )}                    
                </Row>            
        </ListGroup.Item>
    )
}

const GroupList = ({ groups }) => { 

    const {t} = useTranslation()

    const renderGroupList = () => {
        return groups.map((group, i) => {
            return(
                <GroupListItem group={group}  key={i} id={i}/>
            )
        })
    }
    
    return (
        <Card>
            <Card.Header><FontAwesomeIcon icon={faUsers} /> {t('general.groups')}</Card.Header>
            <Card.Body>
                <ListGroup>
                    {renderGroupList()}
                </ListGroup>
            </Card.Body>
        </Card>
        
    )
} 

export default GroupList